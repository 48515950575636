<template>
  <div>
    <section class="list-company" v-if="!loadingData">
      <b-table id="tableEmpresa" responsive :stickyColumn="true" :busy.sync="filtering" :fields="table.fields"
        :items="table.items" no-local-sorting :no-sort-reset="true">
        <template #cell(nome_empresa)="row">
          <div class="comprimento_maximo_celulas">
            <div class="comprimento_maximo_celulas" v-b-tooltip
              :title="row.item.nome_empresa > lengthColumnMaximo ? row.item.nome_empresa : null">
              {{ row.item.nome_empresa }}
            </div>
          </div>
        </template>
        <template #cell(cnpj_cpf)="row">
          <div class="comprimento_maximo_celulas">
            <span class="font-weight-bold comprimento_maximo_celulas"
                            v-if="row.item.documento.length == 11"
                            v-b-tooltip
                            :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
              {{ row.item.documento | VMask('###.###.###-##') }}
              </span>
              <span class="font-weight-bold comprimento_maximo_celulas"
                  v-else
                  v-b-tooltip
                  :title="row.item.documento.length > lengthColumnMaximo ? row.item.documento : null">
              {{ row.item.documento | VMask('##.###.###/####-##') }}
              </span>
          </div>
        </template>
        <template #cell(nome_cidade)="row">
          <div class="comprimento_maximo_celulas">
              {{ row.item.nome_cidade ? row.item.nome_cidade[0] : '-'}}
          </div>
        </template>
        <template #cell(uf_cidade)="row">
          <div class="comprimento_maximo_celulas">
              {{ row.item.uf_cidade ? row.item.uf_cidade[0] : '-'}}
          </div>
        </template>
        <template #cell(sigla_operador)="row">
          <div class="comprimento_maximo_celulas">
              {{ row.item.sigla_operador ? row.item.sigla_operador : '-'}}
          </div>
        </template>
        <template #cell(situacao_cadastro)="row">
          <RowSituacaoCadastro
              :length-column-maximo="lengthColumnMaximo"
              :situacao-cadastro="row.item.situacao_cadastro"
              classe="comprimento_maximo_celulas"
          />
        </template>
        <template #cell(acoes)="row">
            <feather-icon id="view-company" role="button" class="text-custom-blue mr-1" icon="EyeIcon" size="18" @click.prevent="viewCompany(row.item.id_empresa)" href="#"/>
            <feather-icon v-if="canEditCompanies" id="edit-company" role="button" class="text-custom-blue mr-1" icon="EditIcon" @click.prevent="companyEdit(row.item)" size="18" />
            <feather-icon v-if="canEditCompanies" id="remove-company" role="button" class="text-custom-blue mr-1" icon="XCircleIcon" @click="removeCompany(row.item.id_empresa)" size="18" />
        </template>
      </b-table>
      <div class="pb-2 px-1">
        <span v-if="withoutCompany" class="ml-1">Nenhuma empresa nacional encontrada</span>
        <b-col sm="12" class="mt-2">
          <CustomPagination :paginacao="paginationData" @page-cliked="updateCurrentPage" />
        </b-col>
      </div>
    </section>
  </div>
</template>

<script>

import { BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend } from 'bootstrap-vue'
import CustomPagination from '@/views/components/custom/pagination/CustomPagination.vue'
import { ValidationObserver, ValidationProvider } from "vee-validate"
import vSelect from 'vue-select'
import TableFilter from '@/views/components/custom/table-filter-section/TableFilter.vue';
import {resetCompanyGroup, setCompanyEditIdEmpresa, setCompanyGroupArray, setCompanyEditWhenCreatingGroup, setCompanyEditWhenEditingGroup, setIdEmpresa, setCompanyGroupFilter} from '@core/utils/store/setStore'
import {getCompanyGroup } from '@core/utils/store/getStore';
import RowSituacaoCadastro from "@/views/components/custom/empresa/RowSituacaoCadastro.vue";

export default {
  title: "Listagem das empresas do grupo",

  components: {
    RowSituacaoCadastro,
    BForm, BCol, BRow, BFormGroup, BFormInput, BButton, BTable, BFormDatepicker, BInputGroup, BInputGroupAppend,
    CustomPagination,
    ValidationObserver, ValidationProvider,
    vSelect,
    TableFilter
  },
  props: {
    companiesEdit: {
      type: Array
    },
    canEditCompanies: {
      type: Boolean
    },
    clear: {
      type: Boolean,
      default: false,
      required: false,
    },
    filterProp: {
      type: Boolean,
      default: false,
      required: false,
    },
    companiesFilter: {
      required: false,
    },
  },
  data() {
    return {
      filtering: false,
      filter: false,
      loadingData: false,
      paginationData: {
        currentPage: 1,
        totalLines: 0,
        fromLine: 0,
        toLine: 0,
        defaultSize: 10,
      },
      table: {
        fields: [
          { key: "nome_empresa", label: "NOME FANTASIA", sortable: false, class: 'width_columns width_columns_header width_columns_header_company' },
          { key: "cnpj_cpf", label: "CNPJ | CPF", sortable: false, class: 'width_columns width_columns_header' },
          { key: "nome_cidade", label: "CIDADE", sortable: false, class: 'width_columns_small width_columns_header' },
          { key: "uf_cidade", label: "UF", sortable: false, class: 'width_columns width_columns_header' },
          { key: "sigla_operador", label: "OPERADOR", sortable: false, class: 'width_columns width_columns_header' },
          { key: "situacao_cadastro", label: "STATUS", sortable: false, class: 'width_columns width_columns_header' },
          { key: "acoes", label: "AÇÕES", sortable: false, class: 'width_columns_small width_columns_header' }
        ],
        items: [],
        optionsSize: [10, 25, 50, 100],
        columnOrder: 'id_grupo',
        order: 'asc'
      },
      withoutCompany: false,
      modalObservation: {
        modalShow: false,
        infoText: 'Nenhuma observação encontrada.'
      },
      lengthColumnMaximo: 28,
      companies : null,
    }
  },

  mounted() {
    this.loadScheduleData()
  },

  methods: {
    companyEdit(company) {
      this.$emit('companyEdit', company)
      if(this.companiesEdit) {
        setCompanyEditWhenEditingGroup(company)
      } else {
        setCompanyEditWhenCreatingGroup(company)
      }
    },

    updateCurrentPage(page) {
      this.paginationData.currentPage = page

      if(this.clear) {
        this.paginationData.curentPage = 1
      }

      this.filter = !this.filter;
      const indexArrayCompanies = page - 1
      this.loadScheduleData(indexArrayCompanies)
    },

    loadScheduleData(index = this.paginationData.currentPage - 1) {
      this.paginationData.defaultSize = 10;

      const companies = this.splitOnPage(this.companies, 10)
      if(companies.length == 1) {
        index = 0
      }

      if(this.filterProp && this.paginationData.currentPage == 1) {
        index = 0
      }

      this.table.items = companies[index]
      this.withoutCompany = false;

      if(this.companies == null) {
        this.withoutCompany = true
      }

      this.startPagination(companies[index],index)  
    },

    startPagination(companies, fromLine) {
      this.paginationData.totalLines = this.getTotalLines()
      this.paginationData.fromLine = this.getFromLine(fromLine, companies)
      this.paginationData.toLine = this.getToLine(companies,fromLine)
    },

    getTotalLines() {
      if(this.companies == null) {
        return 0
      }

      return this.companies.length
    },

    getFromLine(fromLine, companies) {
      if(companies) {
        return fromLine == 0 ? '1' : String(fromLine) + '1';
      }
      
      return fromLine == 0 ? '0' : String(fromLine) + '1';
    },

    getToLine(companies,fromLine) {
      const totalCompanies = companies != null ? companies.length : 0

      if(fromLine == 0) {
        return String(totalCompanies)
      }

      if(totalCompanies == 10) {
        return  String(1 + fromLine) + '0'
      }

      return String(fromLine) + String(totalCompanies)
    },

    splitOnPage(companies, itemPerPage) {
      if(companies == null) {
        return 1;
      }
      
      const pages = [];
      for (let i = 0; i < companies.length; i += itemPerPage) {
        pages.push(companies.slice(i, i + itemPerPage));
      }
      return pages;
    },

    selectCompany(company){
      this.$emit("selectCompany", {company});
    },
    
    removeCompany(idEmpresa){
        let newList = this.companies.filter(company => company.id_empresa != idEmpresa);
        setCompanyEditIdEmpresa(idEmpresa)
        setCompanyGroupArray(newList);
    },

    viewCompany(idCompany) {
      setIdEmpresa(idCompany)
      const router = this.$router.resolve( {
          name: 'empresa-show',
          params: {
            idEmpresa: idCompany
          }
      })

      window.open(router.href, '_blank')

    },
  },
  beforeDestroy() {
    resetCompanyGroup()
  },

  created() {
    resetCompanyGroup()
  },

  watch: {
    '$store.state.groupState.companyGroup': {
      handler(companies) {
        this.companies = companies
        this.loadScheduleData()
      },
      deep: true,
    },
    filterProp() {
      this.paginationData.currentPage = 1
    },
    companiesEdit(val) {
      setCompanyGroupArray(val)
    },
    companiesFilter() {
      setCompanyGroupFilter(this.companiesFilter.operador)
    },
    '$store.state.groupState.companyEditWhenEditingGroup' : {
      handler() {
        this.companies = getCompanyGroup()
        this.loadScheduleData()
      },
    },
    clear: {
      handler(value) {
        if (value) {
          this.paginationData.currentPage = 1
        }
      },
      deep: true,
      immediate: true,
    }
  }
}

</script>