<template>
  <b-modal 
    id="confirm-delete" 
    no-close-on-backdrop 
    no-close-on-esc 
    hide-header 
    hide-footer 
    centered 
    v-model="modalShow"
    size="lg"
  >
    <main class="pt-2 px-1 pb-2">
      <validation-observer ref="companyForm">
        <b-row>
          <b-col lg="12" md="12" sm="12" class="mb-2">
            <h2>{{ titleModal}}</h2>
          </b-col>
        </b-row>
        <b-row v-if="!showSearch">
          <b-col lg="12" md="12" sm="12">
            <b-form-group 
            label="CNPJ | CPF | Nome Fantasia" 
            label-for="name_group" 
            label-class="font_size_label"
            class="search-container"
            >
              <b-form-input
              id="company_identifier" 
              v-model="company_identifier"
              class="form-control" 
              placeholder="000.000.000-00"
              />
              <div class="search-input-icon">
                <feather-icon
                  icon="SearchIcon"
                  size="22"
                />
              </div>
          </b-form-group>
        </b-col>
      </b-row>
      
      <b-row>
          <b-col lg="12" md="12" sm="12">
            <CompanyListToAddGroup 
              @companySelected="getCompanySelected"
              :search="company_identifier"
              :isEdit="isEdit"
            />
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" md="12" sm="12" class="mt-2">
            <b-form-group
                label="Operador *" 
                label-for="operator" 
                label-class="font_size_label"
              >
              <validation-provider
                #default="{ errors }"
                name="operador"
                rules="required"
              >
                <v-select
                  id="operator"
                  :options="departamentsOptions"
                  v-model="operator"
                  placeholder="Selecione"
                  label="descricao"
                  item-text="operator"
                  item-value="operator"
                  :clearable="false"
                >
                  <span slot="no-options">Nenhuma opção selecionável.</span>
                </v-select>
                <small v-if="errors[0]" class="text-danger">
                  {{errors[0]}}
                </small>
              </validation-provider>
              </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col lg="12" md="12" sm="12">
            <section class="buttons">
              <b-button id="save-empresa"
                  class="mr-2"
                  variant="custom-blue"
                  @click.prevent="addCompany()"
              >
                  <feather-icon icon="CheckIcon" class="mr-50"/>
                  <span class="align-middle">{{ titleButton }}</span>
              </b-button>
              <b-button id="cancel-edit-empresa"
                  variant="outline-danger"
                  @click.prevent="openModalConfirm"
              >
                  <feather-icon icon="XIcon" class="mr-50"/>
                  <span class="align-middle">Cancelar</span>
              </b-button>
          </section>
          </b-col>
        </b-row>
      </validation-observer>
    </main>
  </b-modal>
</template>

<script>
import { BModal, BButton, BForm, BCol, BRow, BFormGroup, BFormInput } from 'bootstrap-vue';
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from '@validations'

import vSelect from "vue-select"
import CompanyListToAddGroup from './CompanyListToAddGroup.vue';
import {getCompanyEditWhenCreatingGroup, getCompanyEditWhenEditingGroup } from '@core/utils/store/getStore';
import {setCompanyEditWhenCreatingGroup, setCompanyEditWhenEditingGroup, resetCompanyEditWhenCreatingGroup} from '@core/utils/store/setStore'

export default {

  components: {
    BModal, BButton, BForm, BCol, BRow, BFormGroup, BFormInput, CompanyListToAddGroup, vSelect,
    ValidationProvider, ValidationObserver
  },

  props: {
    modalShow: {
      required: true,
      type: Boolean
    },
    departamentsOptions: {
      required: true,
      type: Array
    },
    isEdit: {
      required: false,
      type: Boolean
    }
  },

  data() {
    return {
      required,
      companies: [],
      company_identifier: "",
      operator: "",
      company_added: null
    }
  },

  computed: {
    titleModal() {
      return getCompanyEditWhenCreatingGroup() ||  getCompanyEditWhenEditingGroup() ? 'Editar Empresa' : 'Adicionar Empresa'
    },

    showSearch() {
      return getCompanyEditWhenCreatingGroup() || getCompanyEditWhenEditingGroup()
    },

    titleButton() {
      return getCompanyEditWhenCreatingGroup() || getCompanyEditWhenEditingGroup() ? 'Salvar Empresa' : 'Adicionar Empresa'
    },
    showModals() {
      return getCompanyEditWhenCreatingGroup() ||  getCompanyEditWhenEditingGroup()
    }
  },

  methods: {

    async addCompany() {
      try {
        const success = await this.$refs.companyForm.validate();
  
        if(success) {
          const companyAdded = this.defineCompanyHandle()
            this.$emit('companyAdded', companyAdded)
            if(this.showModals){
              this.openModalSuccess();
            }
            this.$emit('modalClose', true)
  
            this.clearForm()
        }

      } catch(e) {
        this.openModalErrorAddCompany()
      }
      
      this.clearForm()
    },

    defineCompanyHandle() {
      let companyAdded
      if(getCompanyEditWhenCreatingGroup()) {
        companyAdded = this.formatObjCompany(getCompanyEditWhenCreatingGroup(), this.operator)
        this.companyEditedWhenCreatingGroup(companyAdded)
      } else if(getCompanyEditWhenEditingGroup()) {
        companyAdded = this.formatObjCompany(getCompanyEditWhenEditingGroup(), this.operator)
        this.companyEditedWhenEditingGroup(companyAdded)
      } else {
        companyAdded = this.formatObjCompany(this.company_added[0], this.operator)
      }

      return companyAdded
    },

    formatObjCompany(company, operator) {
      return {
        ...company,
        id_operador : operator.id_operador ? operator.id_operador : operator.id_departamento,
        descricao_operador : operator.descricao,
        sigla_operador: operator.sigla 
      }
    },

    companyEditedWhenCreatingGroup(companyAdded) {
      if(getCompanyEditWhenCreatingGroup()) {   
        setCompanyEditWhenCreatingGroup(companyAdded)
      }
    },

    companyEditedWhenEditingGroup(companyAdded) {
      if(getCompanyEditWhenEditingGroup()) {
        setCompanyEditWhenEditingGroup(companyAdded)
      }
    },

    clearForm() {
      this.operator = ""
      this.company_identifier = ""
      resetCompanyEditWhenCreatingGroup()
    },

    cancelModal(){
      this.$emit('modalClose', true)
      this.clearForm()
    },

    getCompanySelected(company) {
      this.company_added = company
    },

    openModalErrorAddCompany() {
      this.$swal({
          title: 'Ocorreu um erro.',
          icon: 'warning',
          iconColor: "red",
          html: '<div>É obrigatório selecionar uma empresa para continuar.</div>',
          confirmButtonText: 'Ok',
          customClass: {
            confirmButton: "btn btn-custom-blue"
          },
          buttonsStyling: false
      })
    },
    openModalSuccess() {
      this.$swal({
          title: '',
          icon: 'success',
          iconColor: "#28c76f",
          html: '<div>Alteração salva com sucesso.</div>',
          confirmButtonText: 'Ok!',
          customClass: {
            confirmButton: "btn btn-outline-success"
          },
          buttonsStyling: false
      })
    },
    openModalConfirm() {
      if(!this.showModals){
        this.cancelModal();
      }else{
        this.$swal({
            title: 'Tem certeza de que deseja fechar sem salvar as alterações?',
            icon: 'warning',
            iconColor: "orange",
            html: '<div>As mudanças não salvas serão perdidas.</div>',
            confirmButtonText: 'Continuar editando',
            showCancelButton: true,
            cancelButtonText: 'Sim, cancelar',
            customClass: {
              confirmButton: "btn btn-custom-blue mr-2",
              cancelButton: 'btn btn-outline-warning'
            },
            buttonsStyling: false
        }).then((result) => {
          if (result.isDismissed) {
            this.cancelModal();
          }
      })
      }
  }

  },

  beforeDestroy() {
    this.clearForm()
    resetCompanyEditWhenCreatingGroup()
  },

  watch: {
    '$store.state.groupState.companyEditWhenCreatingGroup': {
      handler(company) {
        if(company != null) {
          this.operator = {
            id_operador : company.id_operador,
            descricao: company.descricao_operador,
            sigla: company.sigla_operador
          }
        } 
      },
      deep: true

    },
    '$store.state.groupState.companyEditWhenEditingGroup': {
      handler(company) {
        if(company != null) {
          this.operator = {
            id_operador : company.id_operador,
            descricao: company.descricao_operador,
            sigla: company.sigla_operador
          }
        } 
      },
      deep: true
    },
  }
}
</script>

<style>
  .buttons {
    text-align: center;
    margin-top: 15px;
  }

  .search-container {
    position: relative;
  }

  .search-input-icon {
    position: absolute;
    top: 50%;
    right: 10px; 
    cursor: pointer;
    color: #b6b2b5;
    font-weight: bold;
  }
</style>